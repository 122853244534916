.overlay-container {

}
.overlay-container .overlay-label {
    font-weight: 900;
    position:relative;
    top: 30px;
    left: 0px;
    text-align:left;
    padding-left: 20px;
    color:#000000;
    background-color:#ffffff92;
}

.page-header {
    min-height: 25vh !important;
}

.nav-link.button{
    display:flex;
    flex-direction: column;
    align-self:center;
    align-items:center;
    align-items: center;
    background-color:#66615b;
    margin: auto;
}

.nav-link .title {
    text-align: center;
    color: #fff;
    font-size: 110%;
    border: 1px solid #fff;
    padding: 1em;
    border-radius:0.25em;
}

.link-black {
    color:#000000 !important;
    font-size: 110%;
    font-weight: 500;
    border: 1px solid gray;
    width: 400px;
    margin: auto;
    border-radius: 4px;

}

.link-black.centered {
    text-align: center;
}
